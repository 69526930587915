<template>
  <div style="padding:16px">
    <el-card>
      <div class="title">
        <h3>网络故障过程统计</h3>
        <el-popover placement="right" width="400" style="margin-left:10px" trigger="hover">
          <img src="../../../../static/1.png" style="width:410px" alt="">
          <i class="el-icon-question" style="cursor: pointer;" slot="reference"></i>
        </el-popover>
      </div>
      <el-form
        ref="queryForm"
        :model="queryParams"
        size="small"
        label-width="90px"
        @submit.native.prevent
      >
        <div class="formModel">
          <el-form-item label="一级机房" prop="bizAreaName">
            <el-cascader
              v-model="queryParams.bizAreaName"
              :options="depOptions"
              :props="{
                multiple: true,
                expandTrigger: 'hover',
                label: 'dept_name',
                value: 'dept_name',
                children: 'children',
              }"
              collapse-tags
              clearable
            ></el-cascader>
          </el-form-item>
          <el-form-item label="建单时间" prop="createdTimeRange">
            <el-date-picker
              v-model="createdTimeRange"
              type="daterange"
              class="formItem"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              clearable
              @keyup.enter.native="handleQuery"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="替换类型" prop="replaceType">
            <el-select
              v-model="queryParams.replaceType"
              placeholder="请选择替换类型"
              clearable
              @keyup.enter.native="handleQuery"
            >
              <el-option
                v-for="item in replaceTypeOptions"
                :key="item.id"
                :label="item.value"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <div class="formItem btns">
            <el-button type="primary" size="small" icon="el-icon-search" @click="handleQuery"
              >搜索
            </el-button>
            <el-button icon="el-icon-refresh" size="small" @click="handleFormRest('queryForm')"
              >重置
            </el-button>
          </div>
        </div>
      </el-form>
    </el-card>
    <el-card style="margin-top: 15px; padding-bottom: 20px">
      <div class="table-list">
        <div class="table-item">
          <h3>全部</h3>
          <el-table v-loading="loading" :data="all" border>
            <el-table-column property="bizAreaName" label="机房">
              <template slot-scope="scope">
                <span :class="scope.row.bizAreaName == '汇总' ? 'bold' : ''">{{
                  scope.row.bizAreaName
                }}</span>
              </template>
            </el-table-column>
            <el-table-column property="count" label="工单量">
              <template slot-scope="scope">
                <span
                  @click="filterOrder(scope.row, '全部')"
                  class="ticket_total"
                  :class="scope.row.bizAreaName == '汇总' ? 'bold' : ''"
                  >{{ scope.row.count }}</span
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="table-item">
          <h3>正常工单</h3>
          <el-table v-loading="loading" :data="normal" border>
            <el-table-column property="bizAreaName" label="机房">
              <template slot-scope="scope">
                <span :class="scope.row.bizAreaName == '汇总' ? 'bold' : ''">{{
                  scope.row.bizAreaName
                }}</span>
              </template>
            </el-table-column>
            <el-table-column property="count" label="工单量">
              <template slot-scope="scope">
                <span
                  @click="filterOrder(scope.row, '正常')"
                  class="ticket_total"
                  :class="scope.row.bizAreaName == '汇总' ? 'bold' : ''"
                  >{{ scope.row.count }}</span
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="table-item">
          <h3>临近超时工单</h3>
          <el-table v-loading="loading" :data="nearTimeOut" border>
            <el-table-column property="bizAreaName" label="机房">
              <template slot-scope="scope">
                <span :class="scope.row.bizAreaName == '汇总' ? 'bold' : ''">{{
                  scope.row.bizAreaName
                }}</span>
              </template>
            </el-table-column>
            <el-table-column property="count" label="工单量">
              <template slot-scope="scope">
                <span
                  @click="filterOrder(scope.row, '临近超时')"
                  class="ticket_total"
                  :class="scope.row.bizAreaName == '汇总' ? 'bold' : ''"
                  >{{ scope.row.count }}</span
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="table-item">
          <h3>已超时工单</h3>
          <el-table v-loading="loading" :data="timeOut" border>
            <el-table-column property="bizAreaName" label="机房">
              <template slot-scope="scope">
                <span :class="scope.row.bizAreaName == '汇总' ? 'bold' : ''">{{
                  scope.row.bizAreaName
                }}</span>
              </template>
            </el-table-column>
            <el-table-column property="count" label="工单量">
              <template slot-scope="scope">
                <span
                  @click="filterOrder(scope.row, '已超时')"
                  class="ticket_total"
                  :class="scope.row.bizAreaName == '汇总' ? 'bold' : ''"
                  >{{ scope.row.count }}</span
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="table-item">
          <h3>尾单</h3>
          <el-table v-loading="loading" :data="finalTicket" border>
            <el-table-column property="bizAreaName" label="机房">
              <template slot-scope="scope">
                <span :class="scope.row.bizAreaName == '汇总' ? 'bold' : ''">{{
                  scope.row.bizAreaName
                }}</span>
              </template>
            </el-table-column>
            <el-table-column property="count" label="工单量">
              <template slot-scope="scope">
                <span
                  @click="filterOrder(scope.row, '尾单')"
                  class="ticket_total"
                  :class="scope.row.bizAreaName == '汇总' ? 'bold' : ''"
                  >{{ scope.row.count }}</span
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-card>
  </div>
</template>
<script>
import { deptTree } from '@/api/monitor';
import { networkList } from '@/api/processStatistics';
import {removeEmptyStringFields} from '@/utils/common'
export default {
  name: 'processStatistics',
  data() {
    return {
      loading: false,
      total: 0,
      all: [], //全部
      finalTicket: [], //尾单
      nearTimeOut: [], //临近超时
      normal: [], //正常工单
      timeOut: [], //已超时
      queryParams: {
        bizAreaName: [], //一级机房
        startCreateTime: '', //开始时间
        endCreateTime: '', //结束时间
        replaceType:'',//替换类型
      },
      createdTimeRange: [], //创建时间
      replaceTypeOptions:[
        //替换类型
        {
          id:1,
          value:'等RMA',
        },{
          id:2,
          value:'自有备件',
        }
      ],
      depOptions: [], //一级机房数据
    };
  },
  created() {
    this.deptTree();
    this.getList();
  },
  methods: {
    //点击工单量跳转
    filterOrder(row, timeoutStatus) {
      let params = {
        ticketId: '', //工单号
        title: '', //工单名称
        bizAreaName: [], //一级机房
        state: '未完成', //工单状态
        isTimeout:
          timeoutStatus == '全部'
            ? ''
            : timeoutStatus == '正常'
            ? '未超时'
            : timeoutStatus == '正常'
            ? '未超时'
            : timeoutStatus, //超时状态
        startCompleteTime: '', //结单开始时间
        endCompleteTime: '', //结单结束时间
        startCreateTime: this.createdTimeRange[0], //创建开始时间
        endCreateTime: this.createdTimeRange[1], //创建截止时间
        isUrging: '', //是否催办
        repairType:"",//维修类型
        replaceType:this.queryParams.replaceType,//替换类型
      };
      if (row.bizAreaName != '汇总') {
        this.depOptions.map((item1) => {
          item1.children.map((item2) => {
            item2.children.map((item3) => {
              if (item3.dept_name == row.bizAreaName) {
                params.bizAreaName = [[item1.dept_name, item2.dept_name, item3.dept_name]];
                return;
              }
            });
          });
        });
      } else {
        params.bizAreaName = [];
      }
      sessionStorage.setItem('network_params', JSON.stringify(params));
      this.$router.push({
        path: '/appManage/monitor/networkMalfunction',
      });
    },
    //切换tab
    getTab(item) {
      this.active = item.id;
      this.queryParams.statisticsType = item.value;
      this.getList();
    },
    //一级机房数据
    deptTree() {
      deptTree().then((res) => {
        this.depOptions = res?.data?.data;
      });
    },
    //列表
    getList() {
      this.loading = true;
      let params = JSON.stringify(this.queryParams);
      let paramsNew = JSON.parse(params);
      paramsNew.bizAreaName = this.queryParams.bizAreaName.map((row) => row.at(-1)); //一级机房
      if (this.createdTimeRange.length) {
        paramsNew.startCreateTime = `${this.createdTimeRange[0]} 00:00:00`; //开始时间
        paramsNew.endCreateTime = `${this.createdTimeRange[1]} 23:59:59`; //截止时间
      }
      paramsNew.bizAreaName = paramsNew.bizAreaName.join(';');
      networkList(removeEmptyStringFields(paramsNew))
       .then((res) => {
          //全部
          this.all = res.data.data.all;
          this.all.push({
            bizAreaName: '汇总',
            count: this.all.reduce((accumulator, currentValue) => {
                return accumulator + currentValue.count;
            }, 0)
          });
          //尾单
          this.finalTicket = res.data.data.overTimeoutAll;
          this.finalTicket.push({
            bizAreaName: '汇总',
            count: this.finalTicket.reduce((accumulator, currentValue) => {
                return accumulator + currentValue.count;
            }, 0)
          });
          //临近超时
          this.nearTimeOut = res.data.data.leastAll;
          this.nearTimeOut.push({
            bizAreaName: '汇总',
            count: this.nearTimeOut.reduce((accumulator, currentValue) => {
                return accumulator + currentValue.count;
            }, 0)
          });
          //正常
          this.normal = res.data.data.normalAll;
          this.normal.push({
            bizAreaName: '汇总',
            count: this.normal.reduce((accumulator, currentValue) => {
                return accumulator + currentValue.count;
            }, 0)
          });
          // 已超时
          this.timeOut = res.data.data.timeoutAll;
          this.timeOut.push({
            bizAreaName: '汇总',
            count: this.timeOut.reduce((accumulator, currentValue) => {
                return accumulator + currentValue.count;
            }, 0)
          });
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.getList();
    },
    // 重置
    handleFormRest(formName) {
      this.$refs[formName].resetFields();
      this.createdTimeRange = [];
      this.handleQuery();
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-popover{
  width: 500px !important;
}
.title {
  display: flex;
  align-items: center;
  margin: 0 0 10px;
  h3 {
    padding: 0;
  }
  i {
    font-size: 20px;
  }
}
.ticket_total {
  color: #1890ff;
  cursor: pointer;
  display: inline-block;
  width: 100%;
  height: 30px;
}
.bold {
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
}
.table-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .table-item {
    width: 45%;
    margin-right: 30px;
  }
  .table-item:last-child {
    margin-right: 0;
  }
}
.tabList {
  display: flex;
  align-items: center;
  p {
    width: 120px;
    height: 40px;
    font-size: 18px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;
    cursor: pointer;
    font-weight: bold;
  }
  .active {
    color: #fff;
    border: 1px solid #1890ff;
    background: #1890ff;
  }
  .unactive {
    color: #666;
    border: 1px solid #eaeaea;
    background: #fff;
  }
  p:nth-child(1){
    border-right: 0;
  }
   p:nth-child(2){
    border-right: 0;
  }
}
.ticketId {
  color: #1890ff;
  cursor: pointer;
}
.standard-blue-link {
  color: #1890ff;
  text-decoration: none;
}
.formModel {
  display: flex;
  flex-flow: row wrap;
}
.formItem {
  // width: 175px !important;
  margin-bottom: 20px;
}
::v-deep .el-form-item--small.el-form-item {
  margin-bottom: 0px;
}
::v-deep .el-card__body {
  padding: 15px 20px 0 20px !important;
}
.btns {
  margin-left: 20px;
}
</style>
