var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "16px" } },
    [
      _c(
        "el-card",
        [
          _c(
            "div",
            { staticClass: "title" },
            [
              _c("h3", [_vm._v("网络故障过程统计")]),
              _c(
                "el-popover",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { placement: "right", width: "400", trigger: "hover" }
                },
                [
                  _c("img", {
                    staticStyle: { width: "410px" },
                    attrs: { src: require("../../../../static/1.png"), alt: "" }
                  }),
                  _c("i", {
                    staticClass: "el-icon-question",
                    staticStyle: { cursor: "pointer" },
                    attrs: { slot: "reference" },
                    slot: "reference"
                  })
                ]
              )
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "queryForm",
              attrs: {
                model: _vm.queryParams,
                size: "small",
                "label-width": "90px"
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "formModel" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "一级机房", prop: "bizAreaName" } },
                    [
                      _c("el-cascader", {
                        attrs: {
                          options: _vm.depOptions,
                          props: {
                            multiple: true,
                            expandTrigger: "hover",
                            label: "dept_name",
                            value: "dept_name",
                            children: "children"
                          },
                          "collapse-tags": "",
                          clearable: ""
                        },
                        model: {
                          value: _vm.queryParams.bizAreaName,
                          callback: function($$v) {
                            _vm.$set(_vm.queryParams, "bizAreaName", $$v)
                          },
                          expression: "queryParams.bizAreaName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "建单时间", prop: "createdTimeRange" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "formItem",
                        attrs: {
                          type: "daterange",
                          "value-format": "yyyy-MM-dd",
                          "range-separator": "至",
                          "start-placeholder": "开始时间",
                          "end-placeholder": "结束时间",
                          clearable: ""
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleQuery($event)
                          }
                        },
                        model: {
                          value: _vm.createdTimeRange,
                          callback: function($$v) {
                            _vm.createdTimeRange = $$v
                          },
                          expression: "createdTimeRange"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "替换类型", prop: "replaceType" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择替换类型",
                            clearable: ""
                          },
                          nativeOn: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.handleQuery($event)
                            }
                          },
                          model: {
                            value: _vm.queryParams.replaceType,
                            callback: function($$v) {
                              _vm.$set(_vm.queryParams, "replaceType", $$v)
                            },
                            expression: "queryParams.replaceType"
                          }
                        },
                        _vm._l(_vm.replaceTypeOptions, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.value, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "formItem btns" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            size: "small",
                            icon: "el-icon-search"
                          },
                          on: { click: _vm.handleQuery }
                        },
                        [_vm._v("搜索 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-refresh", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.handleFormRest("queryForm")
                            }
                          }
                        },
                        [_vm._v("重置 ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "el-card",
        { staticStyle: { "margin-top": "15px", "padding-bottom": "20px" } },
        [
          _c("div", { staticClass: "table-list" }, [
            _c(
              "div",
              { staticClass: "table-item" },
              [
                _c("h3", [_vm._v("全部")]),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      }
                    ],
                    attrs: { data: _vm.all, border: "" }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { property: "bizAreaName", label: "机房" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "span",
                                {
                                  class:
                                    scope.row.bizAreaName == "汇总"
                                      ? "bold"
                                      : ""
                                },
                                [_vm._v(_vm._s(scope.row.bizAreaName))]
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { property: "count", label: "工单量" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "ticket_total",
                                  class:
                                    scope.row.bizAreaName == "汇总"
                                      ? "bold"
                                      : "",
                                  on: {
                                    click: function($event) {
                                      return _vm.filterOrder(scope.row, "全部")
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(scope.row.count))]
                              )
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "table-item" },
              [
                _c("h3", [_vm._v("正常工单")]),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      }
                    ],
                    attrs: { data: _vm.normal, border: "" }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { property: "bizAreaName", label: "机房" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "span",
                                {
                                  class:
                                    scope.row.bizAreaName == "汇总"
                                      ? "bold"
                                      : ""
                                },
                                [_vm._v(_vm._s(scope.row.bizAreaName))]
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { property: "count", label: "工单量" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "ticket_total",
                                  class:
                                    scope.row.bizAreaName == "汇总"
                                      ? "bold"
                                      : "",
                                  on: {
                                    click: function($event) {
                                      return _vm.filterOrder(scope.row, "正常")
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(scope.row.count))]
                              )
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "table-item" },
              [
                _c("h3", [_vm._v("临近超时工单")]),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      }
                    ],
                    attrs: { data: _vm.nearTimeOut, border: "" }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { property: "bizAreaName", label: "机房" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "span",
                                {
                                  class:
                                    scope.row.bizAreaName == "汇总"
                                      ? "bold"
                                      : ""
                                },
                                [_vm._v(_vm._s(scope.row.bizAreaName))]
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { property: "count", label: "工单量" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "ticket_total",
                                  class:
                                    scope.row.bizAreaName == "汇总"
                                      ? "bold"
                                      : "",
                                  on: {
                                    click: function($event) {
                                      return _vm.filterOrder(
                                        scope.row,
                                        "临近超时"
                                      )
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(scope.row.count))]
                              )
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "table-item" },
              [
                _c("h3", [_vm._v("已超时工单")]),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      }
                    ],
                    attrs: { data: _vm.timeOut, border: "" }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { property: "bizAreaName", label: "机房" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "span",
                                {
                                  class:
                                    scope.row.bizAreaName == "汇总"
                                      ? "bold"
                                      : ""
                                },
                                [_vm._v(_vm._s(scope.row.bizAreaName))]
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { property: "count", label: "工单量" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "ticket_total",
                                  class:
                                    scope.row.bizAreaName == "汇总"
                                      ? "bold"
                                      : "",
                                  on: {
                                    click: function($event) {
                                      return _vm.filterOrder(
                                        scope.row,
                                        "已超时"
                                      )
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(scope.row.count))]
                              )
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "table-item" },
              [
                _c("h3", [_vm._v("尾单")]),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      }
                    ],
                    attrs: { data: _vm.finalTicket, border: "" }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { property: "bizAreaName", label: "机房" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "span",
                                {
                                  class:
                                    scope.row.bizAreaName == "汇总"
                                      ? "bold"
                                      : ""
                                },
                                [_vm._v(_vm._s(scope.row.bizAreaName))]
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { property: "count", label: "工单量" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "ticket_total",
                                  class:
                                    scope.row.bizAreaName == "汇总"
                                      ? "bold"
                                      : "",
                                  on: {
                                    click: function($event) {
                                      return _vm.filterOrder(scope.row, "尾单")
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(scope.row.count))]
                              )
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }